<template>
  <div>
    <Header title="門市資訊" :showMemberCenter="false" />

    <div class="text-center branch p-3">
      <h2 class="store__name">麥味登</h2>
      <div class="store__branch">信義三店</div>
      <div>
        <img class="img-fluid store__img mt-2" src="./images/store.svg" />
      </div>
    </div>

    <div class="detail">
      <div class="row">
        <div class="col-6 mb-2">
          <div class="detail__title">店號</div>
            <div class="detail__value">293810932</div>
        </div>
        <div class="col-6 mb-2">
          <div class="detail__title">營業時間</div>
            <div class="detail__value">6:30~13:30</div>
        </div>
        <div class="col-12 mb-2">
          <div class="detail__title">聯絡電話</div>
            <div class="detail__value">02-28712871</div>
        </div>
        <div class="col-12 mb-2">
          <div class="detail__title">地址</div>
            <div class="detail__value">台北市基隆路一段147巷12號</div>
        </div>
      </div>
    </div>

    <div class="page__button">
        <SharedButton class="s-btn-bg-primary"
          >使用優惠券</SharedButton
        >
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Page/Liff/Shared/Header";
import SharedButton from "@/components/Page/Liff/Shared/Button";

export default {
  components: {
    Header,
    SharedButton
  },
  data() {
    return {
      
    };
  },
  computed: {
    ...mapGetters("liffAuth", ["user"]),
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.store {
  &__name {
    font-size: 28px;
    font-weight: bold;
    color: black;
  }

  &__branch {
    font-size: 20px;
    font-weight: bold;
    color: black;
  }
}

.detail{
  padding: 20px 12px;
  background: white;
  margin-bottom: 1px;

  &__title {
    font-weight: 400;
    font-size: 14px;
    color: #636366;    
  }
  &__value {
    font-weight: 600;
    font-size: 18px;
    color: #2C2C2E;
  }
}
</style>
